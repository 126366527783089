<template>
  <div class="breadcrumb">
      <div class="gradiant-bar"></div>

      <div class="grid-container">
          <div class="grid-item">
              <div id="personalFi-bc" class="stage-name"><span class="stage-label">Owner</span> <span
                      class="stage-info">Assets</span></div>

              <div v-if="currentPage === 1" class="stage-num circle circle-current">1</div>
              <div v-else @click="goBackToPage(1)" class="stage-num circle-validated circle-prev">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 1x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" alt="checkmark icon" />
              </div>

              <div class="box-right-start" />
          </div>
          <div class="grid-item">
              <div class="stage-name"><span class="stage-label">Owner</span> <span
                      class="stage-info">Signature</span>
              </div>

              <div v-if="currentPage === 2" class="stage-num circle-current">2</div>
              <div v-else-if="currentPage > 2" @click="goBackToPage(2)"
                  class="stage-num circle-validated circle-prev">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 1x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" alt="checkmark icon" />
              </div>
              <div v-else-if="pageInProgress == 2" class="stage-num circle">–</div>
              <div v-else-if="!nav_validated_business_page" class="stage-num circle">2</div>
              <div v-else class="stage-num circle-validated">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 2x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" alt="checkmark icon" />
              </div>

              <div class="box-right" />
              <div class="box-left" />
          </div>
          <div class="grid-item">
              <div class="stage-name"><span class="stage-label">Signature</span> <span
                      class="stage-info">Status</span></div>

              <div v-if="currentPage === 3" class="stage-num circle-current">3</div>
              <div v-else-if="currentPage > 3" @click="goBackToPage(3)"
                  class="stage-num circle-validated circle-prev">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 1x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" alt="checkmark icon" />
              </div>
              <div v-else-if="pageInProgress == 3" class="stage-num circle">–</div>
              <div v-else-if="!nav_validated_relationship_page" class="stage-num circle">3</div>
              <div v-else class="stage-num circle-validated">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 2x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" alt="checkmark icon" />
              </div>

              <div class="box-right" />
              <div class="box-left" />
          </div>
          <!-- <div class="grid-item">
              <div class="stage-name"><span class="stage-label">Owners</span></div>

              <div v-if="currentPage === 4" class="stage-num circle-current">4</div>
              <div v-else-if="currentPage > 4" @click="goBackToPage(4)"
                  class="stage-num circle-validated circle-prev">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 1x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" />
              </div>
              <div v-else-if="pageInProgress == 4" class="stage-num circle">–</div>
              <div v-else-if="!nav_validated_owners_page" class="stage-num circle">4</div>
              <div v-else class="stage-num circle-validated">
                  <img class="checkmark" srcset="@/assets/images/bc-check.png 2x, @/assets/images/bc-check-2x.png 2x"
                      src="@/assets/images/bc-check-2x.png" />
              </div>
              <div class="box-left" />
          </div> -->
      </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BreadcrumCreditOwner',
  props: {
  },
  components: {

  },
  data() {
      return {

      }
  },
  computed: mapGetters('businessCreditOwner', ['debug', 'type', 'currentPage', 'pageInProgress', 'nav_validated_assets_page', 'nav_validated_signature_page', 'nav_validated_status_page']),
  methods: {
      goBackToPage(i) {
          if (i >= this.currentPage) return
          switch (i) {
              case 1:
                  this.$router.push({ name: 'Business Owner Assets' })
                  break
              case 2:
                  this.$router.push({ name: 'Business Owner Signature' })
                  break
              case 3:
                  this.$router.push({ name: 'Business Status' })
                  break
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.gradiant-bar {
  height: 20px;
  background: linear-gradient(180deg, $grayVlight 0%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
}

.grid-container {
@include media-breakpoint-up(sm) {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
}
</style>