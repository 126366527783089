<template>
  <div class="business-signatures">
    <Header />
    <BreadcrumbCreditOwner/>
    <MainId />

    <div class="ll-body container" role="main">

      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Business Credit Application</div>

          <div class="v-spacer-30" />
          <div id="commercial-page-errors" />
          <div class="v-spacer-30" />

          <h1 class="section-title page-title">Disclosures</h1>
          <div class="disclosure">
            <span class="txt-bold">Important Information About Procedures for Opening a New Account </span>
            <span>To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means to you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.</span>
          </div>

          <div class="v-spacer-50" />

          <h2 class="section-title">Signatures</h2>
          <div class="signature">
            <span>Each person(s) signing this Application certifies that (I) s/he is authroized to execute and deliver this Application and the agreements set forth or referred to herein on behalf of the business named above (the "Applicant"), and (ii) the Application is for business credit only and not consumer credit, and (iii) all information and documents submitted are true, correct, and complete. The signer(s) aurthorizes First Hawaiian Bank ("Bank") and First Hawaiian Leasing, Inc. ("FHL") both now and in the future, to obtain consumer credit reports in his/her name(s) as individuals, to obtain business credit reports on the Applicant, and to provide credit bureaus and others with information about Bank's experience with both him/her and the Applicant. The Applicant acknowledges that if additional information becomes available which would have influenced the Bank's decision to approve this Application prior to the issuance aof the credit card(s) or to the g of the transaction, the Bank reserves the right to withdraw such approval. Applicant designates the authorized signer(s) below to receive all communications from the Bank.
            <br /><br />By Signing below, each person(s) signing this Application confirms that s/he has reviewed and agrees to the Terms and Conditions that appear on page 3 of this Application. Print name and title next to authorized signature. List all titles held. </span>
          </div>

          <div class="v-spacer-30" />


          <div v-if="errCnt" class="page-error-container" :data-test="`ll-bc-owner`">
            <div class="page-error-icon-container">
              <img class="page-error-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div id="personal_info">
            <div class="review-panel">
              <div class="review-row">
                <div class="review-value"><label :for="`${this.owner.first_name}-${this.owner.last_name}-signature`">Signature</label></div>
                <div class="review-value">Full name</div>
                <div class="review-value">Title</div>
                <div class="review-value">Date</div>
              </div>
              <hr class="solid" />
            
                <div class="review-row" :id="`business-owner-signature`">
                  <div class="review-value">
                    <div class="ll-item ll-fname">
                      <input maxlength="512" :placeholder="`${this.owner.first_name} ${this.owner.last_name}`" class="ll-textfield tf-fname" :id="`${this.owner.first_name}-${this.owner.last_name}-signature`" name="owner-signature"
                        :data-test="`ll-bc-owner-signature`" type="text" v-model="signatures"
                        :class="missingSignature || invalidSignature ? 'll-textfield-error' : 'll-textfield'"
                        v-on:blur="validateSignature()">
                      <div v-if="missingSignature || invalidSignature" class="form-error-msg"
                        :data-test="`ll-bc-owner-signature-err`">
                        <img class="form-error-icon"
                          srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                          src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                        <div role="alert" class="form-error-txt">Please enter type your full name</div>
                      </div>
                    </div>
                  </div>
                  <div class="review-value" :data-test="`ll-bc-owner-fname`">
                    {{ this.owner.first_name }} {{ this.owner.middle_initial }} {{ this.owner.last_name }}
                  </div>
                  <div v-if="this.companyStructure !== `sole`" class="review-value" :data-test="`ll-bc-owner-position`">
                    {{ this.owner.company_role ? this.owner.company_role : "Title" }}
                  </div>
                  <div v-else class="review-value" :data-test="`ll-bc-owner-position`">
                    Sole
                  </div>
                  <div class="review-value" :data-test="`ll-bc-owner-date`">
                    {{ this.owner.signed_date ? this.owner.signed_date : "pending" }}
                  </div>
                </div>
                <hr class="solid" />
              
            </div>
          </div>

          <div class="v-spacer-50" />

          <h2 class="section-title">Commercial Loan Appraisal Notice</h2>
          <div class="signature">
            <span>Notice of Right to Receive Copy of Appraisal Report. If the loan or line of credit you are applying for will be secured by a first lien on a dwelling, we may order an appraisal to determine your property's value in connnection with your application for credit, and charge you for the appraisal. We will promptly give you a copy of any appraisal, even if your application is denied or withdrawn. You will have a minimum of three business days to review your appraisal before you sign your credit documents. You can pay for an additional appraisal for your own use at your own cost.</span>
          </div>

          <div class="v-spacer-50" />

          <div class="description">Guaranty (All owner(s), members, and/or general partner(s) must complete. Attach a separate sheet if necessary.)</div>
          <div class="signature">
            <div class="txt-row">
              <div class="txt-column">To induce First Hawaiian Bank ("Bank") or First Hawaiian Leasing, Inc. ("FHL") to enter into a Promissory Note, Business FirstLine Agreement, a business Yes-Check Agreement, a Priority Destinations World Elite Business Program Agreement, a Cash Rewards business Credit Card Agreement, a Cash Rewards Business Credit Card Agreement, a Priority Rewards Business Credit Card Agreement, a Priority Rewards Business Program Agreement, a Business Lease Agreement, a FirstLease Agreement, a Business Mastercard Credit Card Agreement, and/or all other applicable agreements with Applicant for the business credit products requested and approved, the undersigned, jointly and severally, if there are more than one signer (the "Guarantor") (i) unconditionally and irrevocably guarantee(s) payment and performance (not of collection) to Bank of any and all amounts owed to FHB, including but not limited to all credit, advances, debts, obligations and liabilities of the Applicant to Bank, under any or all the foregoing agreements, however arising, whether due or not due, absolute or contingent, liquidated or unliquidated (the "Indebtedness"), as well as any extensions, increases, modifications, or renewals of such Indebtedness, (ii) waives notice of acceptance presentment, demand, protest, notice of nonperformance, and any other motive of any kind whatsoever with respect to the Indebtedness, and lack of promptness does not waive the making of any claim or demand hereunder, (iii) waives notice of the existence, creation or incurring of new or additional Indebtedness, (iv) waives notice of renewal, extension, acceleration, change of payment terms, change or release of security, or release or substitution of any one or more Guarantors,</div>
              
              <div class="txt-column">(v) waives any right to require Bank to proceed against Applicant or any other person or entity, proceed against or exhaust any security held by Applicant, or pursue any other remedy in Bank's power whatsoever, (vi) agrees there is no right of subrogation (unless all Indebtedness from Applicant to Bank has been paid in fill), and waives any right to enforce any remedy which Bank now has or may hereafter have against Applicant or any other person or entity, and any benefit of, and any right to participate in, any security now or hereafter held by Bank, (vii) waives the benefit of any statute of limitation affecting liability hereunder or enforcement thereof, (viii) agrees that Bank shall have a lien upon and a right to setoff against all moneys, securities, and other property of Guarantor now or hereafter in the possession of or on deposit with Bank, (ix) agrees any Indebtedness now or hereafter held by Guarantor is hereby subordinated to the Indebtedness of Applicant to Bank, and (x) agrees to pay all costs incurred by Bank in the or all of the Indebtedness, including reasonable attorneys' fees. Guarantor hereby agrees that this is a continuing Guaranty relating to any Indebtedness, including that arising under successive transactions, which shall either continue the Indebtedness or from time to time renew it after it has been satisfied. If this Application is not approved, then this Guaranty shall have no force or effect. Guarantor authorizes Bank and FHL both now and in the future, to obtain consumer credit reports in his/her name(s) as individuals and to provide credit bureaus and others with information about Bank's experience with him/her.</div>
              <div class="v-spacer-30" />
              <div><br /><br /><br />By signing below, each person(s) signing this Guaranty confirms that s/he has reviewed and agrees to the Terms and Conditions that appear on pages 3 and 4 of this Application.</div>
            </div>
          </div>

          <div class="v-spacer-50" />

          <div class="ll-item ll-fname">
            <div class="tf-label"><label :for="`${this.owner.first_name}-${this.owner.last_name}-initials`">Initial (to acknowledge personal liability on the account as described above)<sup class="fhb-red">*</sup></label></div>
            <div class="ll-row row1-container">
                <input maxlength="2"
                  :placeholder="`${this.owner.first_name.charAt(0)}${this.owner.last_name.charAt(0)}`"
                  class="ll-textfield tf-fname" :data-test="`ll-bc-owner-initials`" type="text" :id="`${this.owner.first_name}-${this.owner.last_name}-initials`" :aria-describedby="`${this.owner.first_name}-${this.owner.last_name}-initials-err`"
                  v-model="initials"
                  :class="missingInitials|| invalidInitials ? 'll-textfield-error' : 'll-textfield'"
                  v-on:blur="validateInitials()" required>
                <div :id="`${this.owner.first_name}-${this.owner.last_name}-initials-err`" class="ind-err-container">
                  <div v-if="missingInitials || invalidInitials" class="form-error-msg"
                    :data-test="`ll-bc-owner-initials-error${i - 1}`">
                    <img class="form-error-icon"
                      srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                      src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                    <div role="alert" class="form-error-txt">Please enter a valid first name</div>
                  </div>
                </div>
            
            </div>
          </div>

          <!-- Errors from BE -->
          <div class="v-spacer-40" />
          <div role="alert" class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : <span class="txt-bold">{{ item.error_value }}</span>
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <!-- continue button -->
          <div class="btn-container">
            <button type="button" class="back-btn" @click="handleBack()" data-test="ll-bc-btn-back">
              <img class="back-icon" srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png" alt="" />
              <span class="sr-only">Back</span>
            </button>
            <button :class="'continue-btn'" id="personal-continue-btn" type="button" @click="handleSubmit()"
              data-test="ll-bc-btn-submit">
              <span class="continue-text">Submit Application</span>
            </button>
          </div>
        </section>

        <div class="v-spacer-30" />

      </form>
    </div>

    <Footer />
  </div>
</template>

<script>
import UserInfoApi from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import BreadcrumbCreditOwner from '@/views/business/credit_app_owner/BreadcrumbCreditOwner.vue'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/app/utils'

export default {
  name: 'Getting Started',
  components: {
    Header,
    BreadcrumbCreditOwner,
    MainId,
    Footer,
  },
  data() {
    return {

      errCnt: 0,
      systemErrorMsg: '',
      signatures: '',
      initials: '',

      missingSignature: false,
      invalidSignature: false,

      missingInitials: false,
      invalidInitials: false,
    }
  },
  created() {
    this.setCurrentPage(2);
    console.log("id is: ", this.appId, "and owner id is: ", this.ownerId)
    // this.getOwners();
  },
  computed: {
    ...mapGetters("businessCreditOwner",
      ['currentPage', 'businessOwners', 'appId', 'ownerId', 'companyStructure']
    ),
    owner: {
      get() { return this.businessOwners }
    },
    todaysDate: {
      get() {
        let today = new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today
      }
    },

  },
  mounted() {
    jQuery("html, body").animate({ scrollTop: 0 });

  },
  methods: {
    ...mapActions("businessCreditOwner",
      ['setJWTToken', 'setValidatedNavPage', 'setCurrentPage', 'setSignedDate']
    ),
    async putPage(payload) {
      this.systemErrorMsg = '';
      this.systemErrorArray = [];

      const resp = await UserInfoApi.putCreditAppOwner(this.appId, this.ownerId, localStorage.getItem('token'), payload);
      console.log('--- response ---');
      console.log(resp);

      if (resp && resp.data) {

        if (resp.data.status == "success") {

          // update breadcrumb state
          this.setValidatedNavPage({ name: 'owner-signature', isValid: 1 });

          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400));

          this.$router.push({ name: 'Business Status' })

          Utils.removeProcessingMask('#ll-continue-btn')
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }

          if (resp.data.response && resp.data.response.application_errors && (resp.data.response.application_errors.length > 0)) {
            for (const x of resp.data.response.application_errors) {
              console.log(x.error_message);
              this.systemErrorArray.push(x)
            }
          }
          Utils.removeProcessingMask('#ll-continue-btn')
        }
      }
    },
    handleBack() {
      this.$router.push({ name: 'Business Owner Assets' })
    },
    handleSubmit() {
      Utils.addProcessingMask('#ll-continue-btn')

      this.validatePage();

      this.$nextTick(() => {
        this.errCnt = jQuery('.form-error-txt').length;

        if (!this.errCnt) {
          const payload =
          {
            "page": 2,
            "signature": this.signatures,
            "initials": this.initials
          }
          console.log(payload)
          this.putPage(payload);
        } else {
          this.setValidatedNavPage({ name: 'legal', isValid: 0 });
          if (this.errCnt > 1) {
            jQuery("html, body").animate({ scrollTop: jQuery("#commercial-page-errors").offset().top - 20 });
          } else {
            let offset = 0;

            if (this.missingSignature || this.invalidSignature) {
              offset = jQuery(`#business-owner-signature`).offset().top

            } else if (this.missingInitials || this.invalidInitials) {
              offset = jQuery(`#business-owner-initials`).offset().top

            }

            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask('#ll-continue-btn')
        }
      })
    },
    validatePage() {
   
        this.validateSignature()
        this.validateInitials()
      
    },
    validateSignature() {
      this.missingSignature = this.signatures ? false : true
      if (!this.missingSignature) {
        this.invalidSignature = this.signatures !== `${this.owner.first_name} ${this.owner.middle_initial ? `${this.owner.middle_initial} ` : ""}${this.owner.last_name}`
      }
      if (!this.missingSignature && !this.invalidSignature && !this.owner.signed_date) {
        this.setSignedDate(this.todaysDate)
      }
    },
    validateInitials() {
      this.missingInitials == this.initials ? false : true
      if (!this.missingInitials) {
        this.invalidInitials = this.initials.toLowerCase() !== `${this.owner.first_name.charAt(0).toLowerCase()}${this.owner.last_name.charAt(0).toLowerCase()}`
      }
    },
    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.ll-section {
  text-align: left;
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }

  &+.ll-row {
    margin-top: 2em;
  }
}

.disclosure {
  background-color: rgb(231, 231, 231);
  padding: 25px;
}

.signature {
  border: 3px solid #bbb;
  padding: 25px;
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 0.45em 1em 0.5em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.review-row {
  padding: 0.6em 0.6em 0.75em;

  @include media-breakpoint-up(sm) {
    column-gap: 1em;
    display: flex;
    flex-wrap: wrap;

    >div:first-child {
      flex-basis: 100%;
    }

    >div:nth-child(2) {
      flex: 1;
      margin-left: 1.5em;
    }

    >div:nth-child(3) {
      flex-basis: calc(50% - 2em);
    }
  }

  @include media-breakpoint-up(md) {
    flex-wrap: unset;

    >div:first-child {
      flex-basis: 200px;
    }

    >div:nth-child(2) {
      flex: 1;
      margin-left: 0;
    }

    >div:nth-child(3) {
      flex-basis: calc(50% - 100px);
    }
  }
}

.review-row-even {
  background-color: $white;
}

.review-row-odd {
  background-color: $grayVlight;
}

.review-header {
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.5;
  color: $black;
}

.review-panel .review-header.review-value {
  margin-left: 0;
}

.review-key {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  padding-top: 0.35em;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.review-value {
  font-size: 1.05rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 0.015em;
  text-align: left;

  img {
    line-height: 1;
    margin-top: 0.2em;
  }

  &+.review-value {
    @include media-breakpoint-down(sm) {
      margin-top: 0.5em;
    }
  }
}

.continue-btn,
.continue-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.continue-btn-disabled {
  background: $grayVlight;
  color: $gray;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.txt-column {
  float: left;
  width: 50%;
  padding: 25px;
}

/* Clear floats after the columns */
.txt-row:after {
  content: "";
  display: table;
  clear: both;
}
</style>